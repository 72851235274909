import React, {useState, useRef} from 'react'
import { NavLink } from "react-router-dom";
import {BsCheck2Circle } from 'react-icons/bs'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';

// import required modules
import { EffectCoverflow, Navigation  } from 'swiper/modules';





function Home() {

    return (
        <div className="">
            <section className="h-screen relative">
                <div className="w-full h-full bg-no-repeat bg-center bg-cover home-jumbotron-bg"></div>
                <div className="bg-black top-0 left-0 w-full h-full opacity-80 absolute"></div>
                <div className="w-full h-full flex items-center absolute top-0 left-0 text-white">
                    <div className="ml-20 max-md:ml-10 max-sm:ml-5">
                        <p className=" text-6xl font-semibold max-lg:text-4xl max-sm:text-3xl">Let's Build Something <br /> Extraordinary!</p>
                        <p className="w-5/12 max-lg:w-7/12 max-sm:w-11/12 font-medium max my-6 text-lg max-lg:text-sm max-lg:font-normal max-sm:text-sm">
                            Choose ASTK TRADING (PTY) for all your construction needs, We bring experience,
                            passion, and creativity to every project, promising excellence and customer 
                            satisfaction. Let's collaboate and build something extraordinary together. 
                            Get started by contacting us today!
                        </p>
                        <button className="px-10 py-4 max-md:py-3 max-md:px-8 bg-orange-600 rounded-md text-base max-md:text-base text-white font-semibold">
                            <NavLink to="/contact">
                                Contact Us
                            </NavLink>
                        </button>
                    </div>
                </div>
                <div className="w-full flex justify-center relative">
                    <span className="py-20 px-20 max-md:px-16 max-md:py-16 max-sm:px-12 max-sm:py-12 rounded-full absolute max-md:-bottom-11 max-sm:-bottom-4 -bottom-14 bg-gradient-to-b from-transparent via-black to-black "></span>
                </div>
            </section>

            <section className="bg-cream" id="services">
                <div className="flex flex-wrap justify-center py-16 max-sm:py-8 w-full">
                    <div className="py-16 max-sm:py-8 w-3/12 max-xl:w-5/12 max-md:w-10/12 m-2">
                        <p className="text-4xl  font-bold w-full">Our services</p>
                        <p className="text-3xl font-semibold w-full">what we do</p>
                    </div>
                    <div className="w-3/12 max-xl:w-5/12 max-md:w-10/12 m-2 xl:mx-8">
                        <div className="w-80 max-xl:w-full h-56 bg-residential-construction bg-cover bg-no-repeat bg-center"></div>
                        <p className="font-bold mt-2 text-sm">Residential Construction</p>
                    </div>
                    <div className="w-3/12 max-xl:w-5/12 max-md:w-10/12 m-2">
                        <div className="w-80 max-xl:w-full h-56 bg-commercial-construction bg-cover bg-no-repeat bg-center"></div>
                        <p className="font-bold mt-2 text-sm">Commercial Construction</p>
                    </div>
                    <div className="w-3/12 max-xl:w-5/12 max-md:w-10/12 m-2">
                        <div className="w-80 max-xl:w-full h-56 bg-renovation bg-cover bg-no-repeat bg-center"></div>
                        <p className="font-bold mt-2 text-sm">Remodeling and Reconstruction</p>
                    </div>
                    <div className="w-3/12 max-xl:w-5/12 max-md:w-10/12 m-2 xl:mx-8">
                        <div className="w-80 max-xl:w-full h-56 bg-interior-design bg-cover bg-no-repeat bg-center"></div>
                        <p className="font-bold mt-2 text-sm">Interior Design</p>
                    </div>
                    <div className="w-3/12 max-xl:w-5/12 max-md:w-10/12 m-2">
                        <div className="w-80 max-xl:w-full h-56 bg-sustainable-building bg-cover bg-no-repeat bg-center"></div>
                        <p className="font-bold mt-2 text-sm">Sustainable Building</p>
                    </div>  
                </div>
            </section>

            <section className="py-80 max-lg:py-96 relative rig-tower-bg bg-no-repeat bg-center bg-cover">
                {/* <div className="w-full h-full bg-no-repeat bg-center bg-cover home-jumbotron-bg"></div> */}
                <div className="bg-black top-0 left-0 w-full h-full opacity-90 absolute"></div>
                <div className=" absolute top-0 left-0 text-white w-full ">
                    <p className="text-4xl font-bold text-center pt-24 max-lg:pt-16 max-sm:pt-28">Why choose us?</p>
                    <div className="w-full h-full flex items-center">
                        <div className="flex flex-wrap justify-center py-10 ">
                            <div className="w-3/12 max-lg:w-5/12 max-sm:w-11/12 mb-10 max-lg:mb-5 max-sm:mb-0 m-2">
                                <div className="flex flex-nowrap">  
                                    <p className="text-4xl font-semibold mr-2">1.</p>  
                                    <div>
                                        <p className="text-3xl max-md:text-xl font-semibold w-full">Unparalled Experience</p>
                                        <p className="text-xs mt-2 w-11/12">
                                            with over 10 years of experience in the construction industry, we 
                                            have successfully completed numerous projects, earning the trust and satisfaction OF our clients.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-3/12 max-lg:w-5/12 max-sm:w-11/12 m-2 max-lg:mb-5 max-sm:mb-0 lg:mx-8">
                                <div className="flex flex-nowrap">  
                                    <p className="text-4xl font-semibold mr-2">2.</p>  
                                    <div>
                                        <p className="text-3xl max-md:text-xl font-semibold w-full">Expert Team</p>
                                        <p className="text-xs mt-2 w-full">
                                            Our team of architects, engineers, and skilled workers is handpicked
                                            to ensure the highest level of expertise and professionalism in every project we underttake.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-3/12 max-lg:w-5/12 max-sm:w-11/12 m-2 max-lg:mb-5 max-sm:mb-0">
                                <div className="flex flex-nowrap">  
                                    <p className="text-4xl font-semibold mr-2">3.</p>  
                                    <div>
                                        <p className="text-3xl max-md:text-xl font-semibold w-full">Customer-Centric Approach</p>
                                        <p className="text-xs mt-2 w-full">
                                            We proritize our clients' needs and preferences, working closely with them from the initial concept 
                                            to project completion, ensuring transparency and open communication throughout.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-3/12 max-lg:w-5/12 max-sm:w-11/12 m-2 max-lg:mb-5 max-sm:mb-0">
                                <div className="flex flex-nowrap">  
                                    <p className="text-4xl font-semibold mr-2">4.</p>  
                                    <div>
                                        <p className="text-3xl max-md:text-xl font-semibold w-full">On-Time Delivery</p>
                                        <p className="text-xs mt-2 w-full">
                                            We understand the importance of meeting deadlines. Our well-organized project 
                                            management approach ensures timely without compromising on quality.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-3/12 max-lg:w-5/12 max-sm:w-11/12 m-2 lg:mx-8 max-lg:mb-5 max-sm:mb-0 max-sm:hidden">
                                <div className="flex flex-nowrap">  
                                    <p className="text-4xl font-semibold mr-2">5.</p>  
                                    <div>
                                        <p className="text-3xl max-md:text-xl font-semibold w-full">Quality Assurance</p>
                                        <p className="text-xs mt-2 w-full">
                                            At ASTK TRADING quality is the cornerstone of our services. We use premium materials 
                                            and follow industry best practices to deliver long-lasting and durable construction solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-3/12 max-lg:w-5/12 max-sm:w-11/12 m-2 max-lg:mb-5 max-sm:mb-0 max-sm:hidden">
                                <div className="flex flex-nowrap">  
                                    <p className="text-4xl max-sm:text-3xl font-semibold mr-2">6.</p>  
                                    <div>
                                        <p className="text-3xl max-md:text-xl font-semibold w-full">Safety First:</p>
                                        <p className="text-xs mt-2 w-full">
                                            Safety is our topmost priority, and we strictly adhere to all safety regulations and 
                                            guidelines to create a secure working environment for our team and your property.
                                        </p>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                    <div className="flex justify-center">    
                        <button className="px-10 py-4 text-white font-semibold max-md:py-3 max-md:px-8 bg-orange-600 rounded-md text-base max-md:text-base">
                            <NavLink to="/contact">
                                Contact Us
                            </NavLink>
                        </button>
                    </div>
                </div>
            </section>

            <section className="py-96 max-sm:py-80 relative">
                <div className="w-full h-full bg-cream absolute top-0 left-0"></div>
                {/* <div className="bg-black top-0 left-0 w-full h-full opacity-90 absolute"></div> */}
                <div className="w-full h-full absolute top-0 left-0 flex items-center">
                    <div className="pl-32 max-lg:px-16 max-sm:px-8 w-8/12 max-md:w-full">
                        <p className="text-3xl max-md:text-lg max-md:font-normal pt-16 pb-5 font-bold">At ASTK, our core values serve as the foundation for everything we do. they
                            guide our actions decisions, and interactions, ensuring that we uphold the 
                            highest standard of professionalism and deliver exceptional constructon services to our clients.
                        </p>
                        <ul className="max-sm:text-sm">
                            <li className="text-black pb-3 "><BsCheck2Circle size={30} className="inline"/> <span className="pl-2">Integrity</span></li>
                            <li className="text-black pb-3"><BsCheck2Circle size={30} className="inline"/> <span className="pl-2">Quality</span></li>
                            <li className="text-black pb-3"><BsCheck2Circle size={30} className="inline"/> <span className="pl-2">Customer Focus</span></li>
                            <li className="text-black pb-3"><BsCheck2Circle size={30} className="inline"/> <span className="pl-2">Safety</span></li>
                            <li className="text-black pb-3"><BsCheck2Circle size={30} className="inline"/> <span className="pl-2">Innovation</span></li>
                        </ul>
                    </div>
                </div>                    
            </section>
 
            <section className="pt-24 pb-24 max-lg:pt-0 max-lg:pb-0">
                <div className="w-9/12 max-sm:w-11/12 bg-orange-400  mx-auto rounded-3xl max-lg:hidden">
                    <p className="font-bold text-3xl text-white text-center pt-6 pb-24">
                        What our clients say about us
                    </p>
                </div>
                <div className="relative w-full h-48 mx-auto max-lg:hidden">
                    <div className="w-full h-full overflow-hiden bg-slate-90 absolute -top-28">
                        <Swiper
                            effect={'coverflow'}
                            centeredSlides={true}
                            grabCursor={true}
                            navigation={true}
                            slidesPerView={3}
                            // spaceBetween={5}
                            coverflowEffect={{
                            rotate: 10,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: false,
                            }}
                            modules={[EffectCoverflow, Navigation]}
                        >
                            <SwiperSlide>
                                <div className="py-10 bg-cream rounded-lg relative mt-14">
                                    <div className="px-8 py-8 rounded-full bg-worker1 bg-cover bg-no-repeat absolute -top-6 left-10"></div>
                                    <div className="py-4 bg-cream px-12">
                                        <p className="font-bold mb-3">Jacob</p>
                                        <p className="text-sm">
                                            I Could'nt be happier with the work done by ASTK TRADING. from the moment
                                            I met their team knew i was in good hands. The transformed my vision into reality. 
                                            And the final result surpass all my expectation
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="py-10 bg-cream rounded-lg relative mt-14">
                                    <div className="px-8 py-8 rounded-full bg-worker2 bg-cover bg-no-repeat absolute -top-6 left-10"></div>
                                    <div className="py-4 bg-cream px-12">
                                        <p className="font-bold mb-3">Daniel</p>
                                        <p className="text-sm">
                                        ASTK TRADING has done an outstanding job, and I couldn't be happier with the results. The professionalism 
                                        of their team was evident from our first meeting, and they skillfully brought my vision
                                         to life, surpassing all of my expectations in the process.
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="py-10 bg-cream rounded-lg relative mt-14">
                                    <div className="px-8 py-8 rounded-full bg-worker1 bg-cover bg-no-repeat absolute -top-6 left-10"></div>
                                    <div className="py-4 bg-cream px-12">
                                        <p className="font-bold mb-3">Adebayo</p>
                                        <p className="text-sm">
                                            The construction work completed by ASTK TRADING was outstanding. Their team displayed a high level 
                                            of competence, completing the project on time and within budget. The attention to 
                                            detail and commitment to excellence were truly commendable, resulting in a finished 
                                            product that exceeded our hopes.
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="py-10 bg-cream rounded-lg relative mt-14">
                                    <div className="px-8 py-8 rounded-full bg-worker2 bg-cover bg-no-repeat absolute -top-6 left-10"></div>
                                    <div className="py-4 bg-cream px-12">
                                        <p className="font-bold mb-3">Mariam</p>
                                        <p className="text-sm">
                                            ASTK TRADING doesn't just excel in construction; they also offer incredible internship opportunities. 
                                            As an intern, I gained valuable hands-on experience and learned from a team of 
                                            seasoned professionals.
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="py-10 bg-cream rounded-lg relative mt-14">
                                    <div className="px-8 py-8 rounded-full bg-worker1 bg-cover bg-no-repeat absolute -top-6 left-10"></div>
                                    <div className="py-4 bg-cream px-12">
                                        <p className="font-bold mb-3">Martha</p>
                                        <p className="text-sm">
                                            I had the privilege of interning at ASTK TRADING, and it was an enriching experience. 
                                            The company not only values quality construction but also invests in the growth of 
                                            young professionals. The internship program provided me with valuable insights into 
                                            the industry.
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            
                            
                        </Swiper>
                    </div>
                </div>
                <div className="lg:hidden mx-5 ">
                    <p className="font-bold text-3xl max-sm:text-2xl text-black text-center pt-8 pb-5 ">
                        What our clients say about us.
                    </p>
                    <div className="overflow-x-auto overflow-y-visible pb-20 max-sm:pb-16 pt-10">
                        <div className="flex h-full">
                            <div className="flex-shrink-0 max-sm:py-5 py-10 bg-cream rounded-lg  relative w-5/12 max-sm:w-8/12 mr-10">
                                <div className="px-6 py-6 rounded-full bg-worker1 bg-cover bg-no-repeat absolute -top-6 left-10"></div>
                                <div className="pt-2 pb-4 bg-cream px-4 rounded-lg w-full">
                                    <p className="font-bold mb-3">Jacob</p>
                                    <p className="text-xs">
                                        I Couldnt be happier with the work done by ASTK TRADING. from the moment
                                        I met their teami knew i was in good hands. The transformed my vision into reality. 
                                        And the final result surpass all my expectation.
                                    </p>
                                </div>
                            </div>
                            <div className="flex-shrink-0 max-sm:py-5 py-10 bg-cream rounded-lg  relative w-5/12 max-sm:w-8/12 mr-10">
                                <div className="px-6 py-6 rounded-full bg-worker1 bg-cover bg-no-repeat absolute -top-6 left-10"></div>
                                <div className="pt-2 pb-4 bg-cream px-4 rounded-lg w-full">
                                    <p className="font-bold mb-3">Daniel</p>
                                    <p className="text-xs">
                                        ASTK TRADING has done an outstanding job, and I couldn't be happier with the results. The professionalism 
                                        of their team was evident from our first meeting, and they skillfully brought my vision
                                         to life, surpassing all of my expectations in the process.
                                    </p>
                                </div>
                            </div>
                            <div className="flex-shrink-0 max-sm:py-5 py-10 bg-cream rounded-lg  relative w-5/12 max-sm:w-8/12 mr-10">
                                <div className="px-6 py-6 rounded-full bg-worker2 bg-cover bg-no-repeat absolute -top-6 left-10"></div>
                                <div className="pt-2 pb-4 bg-cream px-4 rounded-lg w-full">
                                    <p className="font-bold mb-3">Adebayo</p>
                                    <p className="text-xs">
                                        The construction work completed by ASTK TRADING was outstanding. Their team displayed a high level 
                                        of competence, completing the project on time and within budget. The attention to 
                                        detail and commitment to excellence were truly commendable, resulting in a finished 
                                        product that exceeded our hopes.
                                    </p>
                                </div>
                            </div>
                            <div className="flex-shrink-0 max-sm:py-5 py-10 bg-cream rounded-lg  relative w-5/12 max-sm:w-8/12 mr-10">
                                <div className="px-6 py-6 rounded-full bg-worker1 bg-cover bg-no-repeat absolute -top-6 left-10"></div>
                                <div className="pt-2 pb-4 bg-cream px-4 rounded-lg w-full">
                                    <p className="font-bold mb-3">Mariam</p>
                                    <p className="text-xs">
                                        ASTK TRADING doesn't just excel in construction; they also offer incredible internship opportunities. 
                                        As an intern, I gained valuable hands-on experience and learned from a team of 
                                        seasoned professionals.
                                    </p>
                                </div>
                            </div>
                            <div className="flex-shrink-0 max-sm:py-5 py-10 bg-cream rounded-lg  relative w-5/12 max-sm:w-8/12 mr-10">
                                <div className="px-6 py-6 rounded-full bg-worker2 bg-cover bg-no-repeat absolute -top-6 left-10"></div>
                                <div className="pt-2 pb-4 bg-cream px-4 rounded-lg w-full">
                                    <p className="font-bold mb-3">Martha</p>
                                    <p className="text-xs">
                                        I had the privilege of interning at ASTK TRADING, and it was an enriching experience. 
                                        The company not only values quality construction but also invests in the growth of 
                                        young professionals. The internship program provided me with valuable insights into 
                                        the industry.
                                    </p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="py-56 max-sm:py-40 relative">
                <div className="w-full h-full bg-no-repeat bg-center bg-cover grey-site-workers-bg absolute top-0 left-0"></div>
                <div className="bg-black top-0 left-0 w-full h-full opacity-90 absolute"></div>
                <div className="w-full h-full absolute top-0 left-0 flex items-center">
                    <div className="w-8/12 max-md:w-full text-center text-white mx-auto" >
                        <p className="text-4xl font-bold">Learn From us</p>
                        <p className="text-sm my-5">
                            Dive into the world of construction excellence and wisdom with our team. Here, we share our profound 
                            industry insights, expert tips, and stories of success that have shaped our journey. Whether you're a 
                            seasoned professional or just starting, our wealth of knowledge will inspire, educate, and empower you 
                            in your construction endeavors. 
                        </p>
                        <button className="px-10 py-4 max-md:py-3 max-md:px-8 bg-orange-600 rounded-md text-base max-md:text-base text-white font-semibold ">
                            <NavLink to="/course">
                                Learn More
                            </NavLink>
                        </button>
                    </div>
                </div>                    
            </section>

            <section className="">
                <p className="text-3xl font-semibold text-center text-black w-full pt-5">Gallery</p>
                <div className="flex flex-wrap justify-center pb-16 pt-8 max-sm:py-8 w-full">
                    <div className="w-3/12 max-xl:w-5/12 max-md:w-10/12 m-2">
                        <div className="w-80 max-xl:w-full h-56 bg-sustainable-building  bg-cover bg-no-repeat bg-center"></div>
                    </div>
                    <div className="w-3/12 max-xl:w-5/12 max-md:w-10/12 m-2 ">
                        <div className="w-80 max-xl:w-full h-56 home-jumbotron-bg  bg-cover bg-no-repeat bg-center"></div>
                    </div>
                    <div className="w-3/12 max-xl:w-5/12 max-md:w-10/12 m-2">
                        <div className="w-80 max-xl:w-full h-56 bg-interior-design  bg-cover bg-no-repeat bg-center"></div>
                    </div>
                    <div className="w-3/12 max-xl:w-5/12 max-md:w-10/12 m-2">
                        <div className="w-80 max-xl:w-full h-56 bg-residential-construction  bg-cover bg-no-repeat bg-center"></div>
                    </div>
                    <div className="w-3/12 max-xl:w-5/12 max-md:w-10/12 m-2">
                        <div className="w-80 max-xl:w-full h-56 bg-commercial-construction  bg-cover bg-no-repeat bg-center"></div>
                    </div>
                    <div className="w-3/12 max-xl:w-5/12 max-md:w-10/12 m-2">
                        <div className="w-80 max-xl:w-full h-56 bg-sustainable-building  bg-cover bg-no-repeat bg-center"></div>
                    </div>  
                </div>
            </section>

        </div>
    )
}


export default Home;