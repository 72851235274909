import React, {useState, useRef} from 'react'
import {useForm} from 'react-hook-form'
import emailjs from '@emailjs/browser';


function CourseRegistration(props){

    const data = props.data

    const form = useRef()
    const [isLoading, setIsLoading] = useState(false);
    const {register, formState: { errors }} = useForm();
  
    const submitForm = (e) =>{
      e.preventDefault();
      setIsLoading(true);
  
      emailjs.sendForm(
        'service_flkj9of', 
        'template_52cvs2j', 
        form.current, 
        '06yrIlzJQs_0mygHt'
      )
  
      .then((result) => {
        setIsLoading(false); // changing state fo animation on submit of form
        alert('Message sent');
        e.target.reset()
      }, (error) => {
        setIsLoading(false); // changing state fo animation on submit of form
        alert('Message not sent');
      });
    };

    return( 
        <div>
            <section className='bg-cream py-24 max-lg:py-10 pb-10 flex'>
                <div className="lg:w-7/12 max-lg:w-11/12 p-4 bg-white sm:p-6 md:p-8 flex flex-wrap mx-auto">
                    <div className="">
                        <p className='text-3xl font-bold pb-3'>{data[0]}</p>
                        <p>{data[1]}</p>
                        <p className='font-bold mt-3'>Things you would learn</p>
                        <ul className='ml-6'>
                            {
                                data[2].map(item =>(
                                    <li className='text-sm list-disc'>{item}</li>
                                ))
                            }
                        </ul>
                    </div>
                    <form ref={form} onSubmit={submitForm} className="space-y-6 md:justify-evenly w-full max-md:mt-5 flex flex-wrap">
                        <p className='text-3xl font-bold w-full'>Reach Out To Us</p>
                        <div className='md:w-5/12 max-md:w-full'>
                            <label for="Name" className="block text-sm font-medium ">Your Name</label>
                            <input 
                                type={'text'} 
                                name='visitorName'
                                // placeholder='Full Name*' 
                                {...register('visitorName', {required: true, maxLength: 30, minLength:3})}
                                className="bg-[#EBE6D9] block my-2 border-0 outline-none rounded-md p-4 w-full md:w-full" 
                            />
                        </div>

                        <div className='md:w-5/12 max-md:w-full'>
                            <label for="sex" className="block text-sm font-medium ">Gender</label>
                            <select
                                type={'text'}
                                name='visitorGender'
                                {...register('visitorGender')}
                                className="bg-[#EBE6D9] block my-2 border-0 outline-none rounded-md p-4 w-full md:w-full"
                            >
                                <option value={''}></option>
                                <option value={'Male'}>Male</option>
                                <option value={'Female'}>Female</option>
                                <option value={'No Reply'}>Rather not say</option>
                            </select>
                        </div>

                        <div className='md:w-5/12 max-md:w-full'>
                            <label for="phone" className="block text-sm font-medium ">Phone Number</label>
                            <input
                                type={'tel'}
                                name='visitorPhoneNumber'
                                // placeholder='Phone Number'
                                {...register('visitorPhoneNumber', {required: true, maxLength: 11, minLength: 11})}
                                className="bg-[#EBE6D9] block my-2 border-0 outline-none rounded-md p-4 w-full md:w-full"
                            />
                        </div>

                        <div className='md:w-5/12 max-md:w-full'>
                            <label for="email" className="block text-sm font-medium ">Your Email Address</label>
                            <input 
                                type={'email'} 
                                name='visitorEmail'
                                // placeholder='Email Address*' 
                                {...register('visitorEmail', 
                                {
                                    required: true,
                                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
                                })}  
                                className="bg-[#EBE6D9] block my-2 border-0 outline-none rounded-md p-4 w-full md:w-full" 
                            />
                        </div>

                        <div className='md:w-5/12 max-md:w-full'>
                            <label for="Education" className="block text-sm font-medium ">Education</label>
                            <select
                                type={'text'}
                                name='visitorEducationLevel'
                                {...register('visitorEducationLevel')}
                                className="bg-[#EBE6D9] block my-2 border-0 outline-none rounded-md p-4 w-full md:w-full"
                            >
                                <option value={''}></option>
                                <option value={'SSCE'}>SSCE</option>
                                <option value={'ND'}>ND</option>
                                <option value={'Bsc'}>Bsc</option>
                                <option value={'Msc'}>Msc</option>
                                <option value={'Phd'}>PHD</option>
                            </select>
                        </div>

                        <div className='md:w-5/12 max-md:w-full'>
                            <label for="Qualification" className="block text-sm font-medium ">Qualification</label>
                            <input 
                                type={'text'} 
                                name='visitorQualification'
                                // placeholder='Qualifications here*' 
                                {...register('visitorQualification', {required: true, maxLength: 30, minLength:3})}
                                className="bg-[#EBE6D9] block my-2 border-0 outline-none rounded-md p-4 w-full md:w-full" 
                            />
                        </div>

                        <div className='md:w-5/12 max-md:w-full'>
                            <label for="Construction Experience" className="block text-sm font-medium ">Have you worked in construction before</label>
                            <select
                                type={'text'}
                                name='visitorConstructionExperience'
                                {...register('visitorConstructionExperience', {required: true})}
                                className="bg-[#EBE6D9] block my-2 border-0 outline-none rounded-md p-4 w-full md:w-full"
                            >
                                <option value={''}></option>
                                <option value={'Yes'}>Yes</option>
                                <option value={'No'}>No</option>
                            </select>
                        </div>

                        <div className='md:w-5/12 max-md:w-full'>
                            <label for="Construction Experience" className="block text-sm font-medium ">If yes how Long</label>
                            <select
                                type={'text'}
                                name='visitorConstructionExperienceTime'
                                {...register('visitorConstructionExperience')}
                                className="bg-[#EBE6D9] block my-2 border-0 outline-none rounded-md p-4 w-full md:w-full"
                            >
                                <option value={''}></option>
                                <option value={'1-5 Months'}>1-5 Months</option>
                                <option value={'6-12 Months'}>6-12 Months</option>
                                <option value={'1-3 Years'}>1-3 Years</option>
                                <option value={'3-5 Years'}>3-5 Years</option>
                                <option value={'6-10 Years'}>6-10 Years</option>
                            </select>
                        </div>

                        <div className='md:w-5/12 max-md:w-full'>
                            <label for="Country" className="block text-sm font-medium ">Country of Residence</label>
                            <input 
                                type={'text'} 
                                name='visitorResidence'
                                // placeholder='Full Name*' 
                                {...register('visitorResidence', {required: true, maxLength: 30, minLength:3})}
                                className="bg-[#EBE6D9] block my-2 border-0 outline-none rounded-md p-4 w-full md:w-full" 
                            />
                        </div>
                        <div className='md:w-5/12 max-md:w-full'>
                            <label for="Nationality" className="block text-sm font-medium ">Nationality</label>
                            <input 
                                type={'text'} 
                                name='visitorNationality'
                                // placeholder='Full Name*' 
                                {...register('visitorNationality', {required: true, maxLength: 30, minLength:3})}
                                className="bg-[#EBE6D9] block my-2 border-0 outline-none rounded-md p-4 w-full md:w-full" 
                            />
                        </div>
                      
                        <div className='md:w-5/12 max-md:w-full'>
                            <label for="Message" className="block   text-sm font-medium ">Leave A Message</label>
                            <textarea 
                                rows={5}
                                // placeholder='Leave a message' 
                                type={'text'} 
                                name='visitorMessage'
                                {...register('visitorMessage')}  
                                className="bg-[#EBE6D9] block my-2 border-0 outline-none rounded-md p-4 w-full md:w-full" 
                            />
                        </div>

                        <div className='md:w-full'>
                            <input 
                                readOnly
                                value={data[0]}
                                type={'text'}
                                name='visitorCourse'
                                className='hidden'
                            />
                        </div>
                        <button 
                            type='submit' 
                            disabled={isLoading} 
                            className="w-full md:w-5/12 text-white bg-orange-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                            {isLoading ? 'Sending...' : 'Submit'}
                        </button>
                    </form>
                </div>
            </section>
        </div>
    )
}



export default CourseRegistration