let data = {
    firstCourse:[
        "4-Week Course", 
        "Embark on a dynamic 4-week journey into the world of construction and buildings. Gain a quick but comprehensive understanding of key aspects that will set you on the path to success in the industry",
        [
            "Introduction to Construction Industry",
            "Building Materials and Technology",
            "Site Management and Safety",
            "Green Building and Sustainability"
        ]
    ],
    secondCourse:[
        "12-Week Course", 
        "Ready for a deeper dive? Join our 12-week course and explore the intricate realms of construction and buildings. Get ready to master the essential skills and knowledge to make your mark in this dynamic field.",
        [
            "Construction Project Management",
            "Structural Engineering",
            "Architectural Design",
            "Building Codes and Regulations",
            "Quality Control and Assurance",
            "Cost Estimation and Budgeting"
        ]
    ],
    thirdCourse:[
        "24-Week Course",
        "For the dedicated learners, our 24-week course is your gateway to becoming a true expert in construction and building practices. Immerse yourself in a comprehensive program designed to equip you with the knowledge and skills for a thriving career in this exciting industry.",
        [
            "Construction Methods and Techniques",
            "Electrical and Mechanical Systems",
            "Project Planning and Scheduling",
            "Construction Law and Contracts",
            "BIM (Building Information Modeling)",
            "Case Studies and Real-World Projects",
            "Safety Certifications and Regulations",
            "Procurement and Supply Chain Management",
            "Renovation and Restoration",
            "Professional Development and Networking"
        ]
    ]
}

export default data