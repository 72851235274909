import React from 'react'
import { Link, Outlet } from "react-router-dom";
import Data from '../data/Data.js'



function Courses(){
    let data = Data

    
    return(
        <div>
            <section className="py-48 max-sm:py-40 relative">
                <div className="w-full h-full bg-no-repeat bg-center bg-cover roofing-bg absolute top-0 left-0"></div>
                <div className="bg-black top-0 left-0 w-full h-full opacity-90 absolute"></div>
                <div className="w-full h-full absolute top-0 left-0 flex items-center">
                    <div className="w-8/12 max-md:w-full text-center text-white mx-auto" >
                        <p className="text-4xl font-bold">Building Dreams, Creating Reality</p>
                        <p className="text-sm my-5">
                            At ASTK Trading, we're more than buuilders; we're dream weavers. Our passion lies in turning your construction dreams into
                            concrete reality. Explore a world of construction excellence with us
                        </p>
                    </div>
                </div>                    
            </section>
            
            <section className='bg-cream'>
                <p className="text-4xl font-bold text-center pt-24 max-lg:pt-16 max-sm:pt-5">Courses we offer</p>
                <div className="w-full h-full flex items-center ">
                    <div className="flex flex-wrap justify-center py-10 max-sm:py-4">
                        <div class="w-3/12 max-md:w-10/12 bg-gray-900  rounded-lg shadow ">
                            <div className='w-full h-56 bg-residential-construction bg-center bg-cover bg-no-repeat'></div>
                            <img class="rounded-t-lg" src={"../../src/assets/building-construction-small.jpg"} alt="" />
                            <div class="p-5">
                                <h4 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{data.firstCourse[0]}</h4>
                                <p class="mb-3 font-normal text-white dark:text-gray-300">
                                    Embark on a dynamic 4-week journey into the world of construction and buildings.
                                </p>
                                <div>
                                    <Link to={'4-week-course'} class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-orange-600">
                                        Register
                                        <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="w-3/12 max-md:w-10/12 bg-gray-900 md:mx-5 max-md:my-10 rounded-lg shadow ">
                            <div className='w-full h-56 bg-commercial-construction bg-center bg-cover bg-no-repeat'></div>
                            <img class="rounded-t-lg" src={"../../src/assets/building-construction-small.jpg"} alt="" />
                            <div class="p-5">
                                <h4 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{data.secondCourse[0]}</h4>
                                <p class="mb-3 font-normal text-white dark:text-gray-300">
                                   Interested in delving deeper into the topic? Consider enrolling in our 12-week course.
                                </p>
                                <div>
                                    <Link to={'12-week-course'} class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-orange-600">
                                        Register
                                        <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="w-3/12 max-md:w-10/12 bg-gray-900  rounded-lg shadow ">
                            <div className='w-full h-56 bg-renovation bg-center bg-cover bg-no-repeat'></div>
                            <img class="rounded-t-lg" src={"../../src/assets/building-construction-small.jpg"} alt="" />
                            <div class="p-5">
                                <h4 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{data.thirdCourse[0]}</h4>
                                <p class="mb-3 font-normal text-white dark:text-gray-300">
                                    For committed learners, our 24-week course offers a path to becoming a genuine construction expert.
                                </p>
                                <div>
                                    <Link to={'24-week-course'} class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-orange-600" data>
                                        Register
                                        <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* <Outlet /> */}
                    </div>
                </div>
            </section>
        </div>
    )

}




export default Courses
