import React, {useState, useRef} from 'react'
import img from '../assets/site workers.jpg';
import {useForm} from 'react-hook-form'
import emailjs from '@emailjs/browser';

const Contact = () => {

  const form = useRef()
  const [isLoading, setIsLoading] = useState(false);
  const {register, formState: { errors }} = useForm();

  const submitForm = (e) =>{
    e.preventDefault();
    setIsLoading(true);

    emailjs.sendForm(
      'service_flkj9of', 
      'template_lvi4qzp', 
      form.current, 
      '06yrIlzJQs_0mygHt'
    )

    .then((result) => {
      setIsLoading(false); // changing state fo animation on submit of form
      alert('Message sent');
      e.target.reset()
    }, (error) => {
      setIsLoading(false); // changing state fo animation on submit of form
      alert('Message not sent');
    });
  };




  return (
    <div className='bg-[#EBE6D9]'>
      <div className='w-full h-screen'>
        <section className='home-jumbotron-height relative'>
          <div className='w-full h-full bg-no-repeat bg-center bg-cover contact-jumbotron-bg'></div>
          <div className='bg-black top-0 left-0 w-full h-full opacity-80 absolute'></div>
          <div className='w-full h-full flex items-center absolute top-0 left-0 text-white'>
            <div className='flex flex-col justify-center items-center mx-auto'>
              <p className=' text-6xl font-semibold max-lg:text-4xl max-sm:text-3xl'>
                Contact us
              </p>
              <p className='font-bold text-center px-10 md:w-10/12'>
              Thank you for considering ASTK for your construction needs. We are dedicated to delivering 
              high-quality construction services and ensuring client satisfaction. If you have any questions or if 
              there's anything we can assist you with, please feel free to get in touch with us. Your inquiries are 
              important to us, and we look forward to hearing from you.
              </p>
            </div>
          </div>
        </section>
      </div>
      {/*------------------------Contact form------------------------------*/}
      <div className='  flex flex-wrap pb-20 justify-center'>
        <div className=' mt-6 max-lg:mt-0 w-5/12 max-lg:w-10/12'>
          <h1 className='font-bold text-4xl mb-4'>Get in touch</h1>
          <p className=' w-11/12 max-lg:mb-10'>
            Ready to turn your construction dreams into reality? Contact us
            today to schedule a consultation with our team. Whether you have a
            specific project in mind or need expert advice, we are here to
            assist you at every step of the way.
          </p>
          <div className=' pb-12 mt-10 hidden lg:block'>
            <img src={img} alt='' className='96 w-11/12' />
          </div>
        </div>
        <div className='lg:mt-16 max-lg:w-10/12 w-6/12'>
          <form ref={form} onSubmit={submitForm} className=' bg-white rounded-lg px-12  py-20'>
            <p>Full Name</p>
            <input
              type={'text'} 
              name='visitorName'
              placeholder='Full Name*' 
              {...register('visitorName', {required: true, maxLength: 30, minLength:3})}
              className='bg-[#EBE6D9] block my-4 border-0 outline-none rounded-md p-4 w-full md:w-full'
            />
            <div>
              <label for="phone" className="block text-sm font-medium ">Phone Number</label>
              <input
                  type={'tel'}
                  name='visitorPhoneNumber'
                  placeholder='Phone Number'
                  {...register('visitorPhoneNumber', {required: true, maxLength: 11, minLength: 11})}
                  className="bg-[#EBE6D9] block my-2 border-0 outline-none rounded-md p-4 w-full md:w-full"
              />
            </div>

            <p>Email Address</p>
            <input
              type={'email'} 
              name='visitorEmail'
              placeholder='Email Address*' 
              {...register('visitorEmail', 
              {
                  required: true,
                  pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
              })}  
              className='bg-[#EBE6D9] block my-4 border-0 outline-none rounded-md p-4 w-full md:w-full'
            />
            {errors.visitorEmail && <small className='text-red-900 font-bold'>A correct Email Address is required</small>}

            <p>Leave A Message</p>
            <textarea
              rows={5}
              placeholder='Leave a message' 
              type={'text'} 
              name='visitorMessage'
              {...register('visitorMessage')}  
              className='bg-[#EBE6D9] block my-4 border-0 outline-none rounded-md p-4 w-full md:w-full h-[150px]'
            />
            <button 
              type='submit' 
              disabled={isLoading} 
              className='bg-orange-600 rounded-sm flex justify-center items-center mx-auto px-10 py-2 mt-8 '
          >
              {isLoading ? 'Sending...' : 'Submit'}
          </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
