import React from 'react'

const About = () => {
    const About = [
        {
            head: "About ASTK TRADING",
            content: "At ASTK TRADING we believe that every construction project is an opportunity to turn dreams into reality. As a premier construction company with a passion for excellence, we have been transforming spaces and creating architectural marvels for over 10 years. Our commitment to quality, integrity, and customer satisfaction sets us apart as a trusted name in the construction industry. "
        },
        {
            head: "Our Mission",
            content: " Our mission is to be the leading force in the construction industry, providing innovative solutions that exceed our clients' expectations. We strive to create sustainable, functional, and aesthetically pleasing spaces that inspire and enrich the lives of those who inhabit them. Our core values of integrity, teamwork, and craftsmanship drive us to continuously improve and deliver superior resu "
        },
        {
            head: "Our Expert Team",
            content: " ASTK TRADING boasts a team of highly skilled professionals who are the heartand soul of our success. From experienced architects and engineers to dedicatedcraftsmen, each member of our team brings a wealth of knowledge and expertiseto every project. We foster a collaborative work environment that values creativity,attention to detail, and precision in execution."
        }
    ]
    
    return (
        <div>
            <div className="w-full h-screen">
                <section className="home-jumbotron-height relative ">
                    <div className="w-full h-full bg-no-repeat bg-center bg-cover roofing-bg"></div>
                    <div className="bg-black top-0 left-0 w-full h-full opacity-80 absolute"></div>
                    <div className="w-full h-full flex items-center absolute top-0 left-0 text-white">
                        <div className="flex flex-col justify-center items-center mx-auto">
                            <p className=" text-6xl font-semibold max-lg:text-4xl max-sm:text-3xl">About us</p>
                            <p className=" font-bold text-center px-10 md:w-1/2">
                            At ASTK Trading, we are more than builders; we are creators of spaces, crafters of dreams, and architects 
                            of innovation. With years of expertise in the construction industry, our commitment to excellence, precision, 
                            and quality has earned us a reputation for delivering projects that stand the test of time. Discover our 
                            story, values, and the passionate team dedicated to turning your visions into reality. Welcome to a world 
                            where construction meets craftsmanship, and dreams find their foundation
                            </p>
                        </div>
                    </div>

                </section>
            </div>
            <div className="flex flex-col justify-center items-center mx-12 mb-40 text-justify md:w-1/2 md:mx-auto">
                {
                    About.map(about => (
                        <div className='mb-12 '>
                            <h3 className='font-bold text-xl mb-14'>{about.head}</h3>
                            <p>
                                {about.content}
                            </p>

                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default About