import React from 'react'
import { NavLink } from 'react-router-dom';
import { FaFacebook, FaLinkedin, FaTwitter, FaInstagram } from 'react-icons/fa';





function NavBar() {

    

    return (
        <>
            <div className="bg-cream w-full py-8 px-10 max-sm:px-5 text-black opacity-100 flex flex-wrap justify-between">
                <div>
                    <NavLink to="/" className='flex items-center' >
                        <div className='text-4xl max-md:text-3xl'>
                            <span className="">ASTK</span>
                            <span className='text-orange-700'>-</span>
                            <span className="">TRADING</span>
                        </div>
                    </NavLink>
                </div>
                <ul className="flex flex-nowrap sm:px-5 md:justify-evenly md:mt-1 max-md:mt-1 max-md:w-full">
                    <li className=" text-xl flex flex-wrap max-sm:text-lg sm:normal max-sm:font-medium mr-3 sm:mr-5">
                        <NavLink to="/">
                            <span className='border-b-2 border-orange-700'>Hom</span>
                            e
                        </NavLink>
                    </li>
                    <li className="text-xl max-sm:text-lg max-sm:font-medium text-black mr-3 sm:mr-5">
                        <NavLink to="/about">
                            <span className='border-b-2 border-orange-700'>Abo</span>ut
                        </NavLink>
                    </li>
                    <li className="text-xl max-sm:text-lg max-sm:font-medium text-black sm:mr-5 mr-3">
                        <NavLink to="/contact">
                            <span className='border-b-2 border-orange-700'>Contac</span>t Us
                        </NavLink>
                    </li>
                    <li className="text-xl max-sm:text-lg max-sm:font-medium text-black">
                        <NavLink to="/course">
                            <span className='border-b-2 border-orange-700'>Cour</span>ses
                        </NavLink>
                    </li>
                </ul>
                <div className='flex max-md:hidden sm:mt-1 justify-center items-center max-sm:mt-4 text-black max-sm:w-full max-lg:w-full max-md:w-full'>
                    <div className='pl-4'>
                        <a href='/'>
                        <FaFacebook size={25} />
                        </a>
                    </div>
                    <div className='pl-4'>
                        <a href='/'>
                        <FaLinkedin size={25} />
                        </a>
                    </div>
                    <div className='pl-4'>
                        <a href='/'>
                        <FaTwitter size={25} />
                        </a>
                    </div>
                    <div className='pl-4'>
                        <a href='/'>
                        <FaInstagram size={25} />
                        </a>
                    </div>
                </div>
            </div>  
        </>
    )
}


export default NavBar