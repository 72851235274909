import React, {useState, useRef} from 'react'
import { FaFacebook, FaLinkedin, FaTwitter, FaInstagram } from 'react-icons/fa';
import {useForm} from 'react-hook-form'
import emailjs from '@emailjs/browser';

const Footer = () => {

  const form = useRef()
  const [isLoading, setIsLoading] = useState(false);
  const {register, formState: { errors }} = useForm();

  const submitForm = (e) =>{
    e.preventDefault();
    setIsLoading(true);

    emailjs.sendForm(
      'service_g7vl1p7', 
      'template_1z97qxd', 
      form.current, 
      'o-0Evnky5hPKZw0pY'
    )

    .then((result) => {
      setIsLoading(false); // changing state fo animation on submit of form
      alert('Message sent');
      e.target.reset()
    }, (error) => {
      setIsLoading(false); // changing state fo animation on submit of form
      alert('Message not sent');
    });
  };


  return (
    <div className='relative bg-black text-white'>
      <div className='flex max-sm:flex-col justify-center items-center py-4 max-sm:py-5'>
        <div className='flex max-sm:flex-wrap max-sm:w-full justify-center items-center sm:mr-20 max-sm:order-2 mt-10 max-sm:mt-5'>
          <div className='pl-4'>
            <a href='/'>
              <FaFacebook size={30} />
            </a>
          </div>
          <div className='pl-4'>
            <a href='/'>
              <FaLinkedin size={30} />
            </a>
          </div>
          <div className='pl-4'>
            <a href='/'>
              <FaTwitter size={30} />
            </a>
          </div>
          <div className='pl-4'>
            <a href='/'>
              <FaInstagram size={30} />
            </a>
          </div>
        </div>
        <div className='max-sm:order-1 max-sm:w-full flex justify-center items-center'>
          <form ref={form} onSubmit={submitForm} className=''>
            <p className='py-1 text-sm'>Newsletter</p>
            <input
              className='p-2 text-black border-0 outline-none rounded-s-lg '
              type={'email'} 
              name='visitorEmail'
              placeholder='Email Address*' 
              {...register('visitorEmail', 
              {
                  required: true,
                  pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
              })}  
            />
            <button 
              type='submit' 
              disabled={isLoading} 
              className='bg-orange-600 p-2 rounded-e-lg font-semibold'          >
              {isLoading ? 'Sending...' : 'Submit'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Footer;
