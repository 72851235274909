  import React from 'react'
import {Routes, Route } from 'react-router-dom'


import Home from './pages/Home.js'
import About from './pages/About.js'
import Footer from './components/Footer.js';
import NavBar from './components/NavBar.js';
import Contact from './pages/Contact.js';
import Courses from './pages/Courses.js';
import CourseRegistration from './pages/CourseRegistration.js';
import Data from './data/Data.js'

function App() {

  const data = Data

  return (
    <React.StrictMode>
        <NavBar />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route path='/course' element={<Courses />} />
          <Route path="/course/4-week-course" element={<CourseRegistration data={data.firstCourse} />} />
          <Route path="/course/12-week-course" element={<CourseRegistration data={data['secondCourse']} />} />
          <Route path="/course/24-week-course" element={<CourseRegistration data={data['thirdCourse']} />} />
        </Routes>
        <Footer />
    </React.StrictMode>
    );
}

export default App;
